<template>
  <div id="newsList">
    <Header now-nav="/news"></Header>
    <!--  内容区  -->
    <div class="contentBox">
      <div class="navHead">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="navNews">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/news' }">新闻资讯</el-breadcrumb-item>
          <el-breadcrumb-item>{{ thisSolutionName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="turnHead">
        <p @click="goToPreviousPage" plain>
          < 返回列表
        </p>
      </div>
      <div class="newsBox">
        <!--    左侧---新闻内容    -->
        <div class="rightNews">
          <div class="rightNews_Box">
            <!--***--公司动态-新增3--***-->
            <div v-if="thisSolutionName==='蓉视通科技受邀参加2023石油化工数字化（储运）罐区高峰论坛'" class="solutionForList">
              <div class="newsListHeaderBox">
                <p>{{ thisSolutionName }}</p>
                <span>时间：2023年3月24日</span>
                <span>作者：杨宇</span>
                <span>来源：蓉视通科技</span>
              </div>
              <!--      新闻内容      -->
              <div class="newsListContentBox">
                <p>
                  2023年3月15-17日，2023石油化工数字化（储运）罐区高峰论坛暨第五届全国石油化工罐区测量控制与安全管理技术交流大会在青岛盛大举行。论坛以“会诊罐区隐患，推进安全管理，引领智慧升级”为主题，聚集了石油化工领域优质企业及专家学者，共论罐区智慧化管理及数字技术的发展。
                </p>
                <p>
                  成都蓉视通科技有限公司（简称“蓉视通科技”）作为管网数字化及智能化领域专业供应商受邀参加此次高峰论坛，并携智慧化产品及解决方案与石化领域重要合作伙伴做演讲分享。
                </p>
                <img :src="baseUrl+'imges/news/newsAdd03/newsAdd03_img_3.png'" alt="" style="width: 70%;">
                <p>
                  会议上，蓉视通科技副总马兴乐围绕化工管廊智慧化管理主题，分享了《基于数字孪生的公共管廊智慧管理解决方案》这一内容，深刻指出了化工管廊管理中的痛难点，现目前化工管网的管理痛点主要包括四个方面，第一，管网信息与资料不完整，不准确，不系统，不直观；第二，管网的运行状态不能实时掌握；第三，管网指挥调度与运维缺乏辅助决策支撑；第四，管网异常应急处理能力有待提升等。
                </p>
                <div style="display: flex;justify-content: space-around;flex-wrap: wrap;text-align: center;">
                  <img :src="baseUrl+'imges/news/newsAdd03/newsAdd03_img_1.png'" alt="" style="width: 49%;">
                  <img :src="baseUrl+'imges/news/newsAdd03/newsAdd03_img_2.png'" alt="" style="width: 49%;">
                </div>
                <p>
                  在此背景下，蓉视通科技量身打造了基于数字孪生的公共管廊智慧管理解决方案，该解决方案涉及数字孪生，工业物联网，5G、地理信息技术等多项核心技术，以此实现管廊信息数字可视化，管廊运行状态实时感知，管廊智能运维与调度指挥辅助决策，管廊应急管理及时有效这四大目标，有效解决用户实际问题。
                </p>
                <img :src="baseUrl+'imges/news/newsAdd02/newsAdd02_img_2.png'" alt="" style="width: 70%;">
                <p>
                  在工业物联网浪潮迭起的时代，石油化工行业的数字化、智能化转型是大势所趋，蓉视通科技将时刻把握发展风向标，不断挖掘行业新需求，用科技赋能，以创新驱动，有效助力石油化工行业的智能化、精细化建设，进而有力助推我国石油化工行业的高质量发展。
                </p>
              </div>
              <!--      阅读完成      -->
              <div class="newsListFooter">
                <div class="upDown">
                  <div class="upDown_Box" @click="goNewsList('蓉视通科技——“基于三维可视化的公共管廊智慧管理系统”成功入选2022年智慧化工园区适用技术目录')">
                    下一篇：蓉视通科技——“基于三维可视化的公共管廊智慧管理系统”成功入选2022年智慧化工园区适用技术目录
                  </div>
                </div>
                <p @click="goToPreviousPage" plain class="turnOut">返回列表</p>
              </div>
            </div>

            <!--***--公司动态-新增2--***-->
            <div v-if="thisSolutionName==='蓉视通科技——“基于三维可视化的公共管廊智慧管理系统”成功入选2022年智慧化工园区适用技术目录'" class="solutionForList">
              <div class="newsListHeaderBox">
                <p>{{ thisSolutionName }}</p>
                <span>时间：2022年11月28日</span>
                <span>作者：杨宇</span>
                <span>来源：蓉视通科技</span>
              </div>
              <!--      新闻内容      -->
              <div class="newsListContentBox">
                  <p>
                    2022年10月，中国石油和化学工业联合会化工园区工作委员会公布了“2022年（第二批）智慧化工园区适用技术目录”的名单，蓉视通科技“基于三维可视化的公共管廊智慧管理系统”成功入选！化工园区的智慧化建设是提高园区安全水平、绿色化水平、运营效率等的重要手段，“十四五”规划中提出，我国化工园区要从规范化发展向高质量发展跃升，要重点打造化工园区的智慧化建设工程。
                  </p>
                  <img :src="baseUrl+'imges/news/newsAdd02/newsAdd02_img_1.png'" alt="" style="width: 70%;">
                  <p>
                    蓉视通科技“基于三维可视化的公共管廊智慧管理系统”高度融合了地理信息技术和管廊业务管理逻辑，运用三维建模技术，将管廊管线场景实现物理空间到虚拟空间的全景仿真，并实现管廊管线所有信息的完整和结构化的集成。在可视化模型中，集成管线属性信息、管廊轴剖面图、管线介质信息、单线图、工艺流程图、管线档案等静态信息，以及集成管线运行状态和安全运行监测的实时动态数据，实现公共管廊所有异构设备、数据和系统的互联互通。
                  </p>
                  <img :src="baseUrl+'imges/news/newsAdd02/newsAdd02_img_2.png'" alt="" style="width: 70%;">
                  <span style="display: flex;
                        justify-content: center;
                        font-family: Regular;
                        font-size: 14px;
                        color: #666666;
                        margin: -10px 0 30px;"
                    >智慧管廊综合管理系统示意图</span>
                  <p>
                    目前，蓉视通科技“基于三维可视化的公共管廊智慧管理系统”已在国内多个大型化工园区实现落地应用，有效地为用户解决了实际问题，获得一致好评。此外蓉视通自主研发的公共管廊智慧管理系统也可应用于化工厂/化工园区管廊管理、城市地下综合管网及燃气管网管理，实现对公共管廊的综合、统一动态管理，进而实现公共管廊的智慧运营和科学管理。
                  </p>
                  <p>
                    未来，蓉视通将持续把握物联网发展的风向标，一如既往地致力于开发工业物联网领域的软硬件产品，用科技赋能，以创新驱动，有效地助力化工园区的智慧化建设，也为工业企业数字化、智能化转型提供更多产品和更好的解决方案！
                  </p>

              </div>
              <!--      阅读完成      -->
              <div class="newsListFooter">
                <div class="upDown">
                  <div class="upDown_Box" @click="goNewsList('蓉视通科技受邀参加2023石油化工数字化（储运）罐区高峰论坛')">
                    上一篇：蓉视通科技受邀参加2023石油化工数字化（储运）罐区高峰论坛
                  </div>
                  <div class="upDown_Box" @click="goNewsList('工业物联网软硬件制造商——蓉视通科技精彩亮相IOTE国际物联网展')">
                    下一篇：工业物联网软硬件制造商——蓉视通科技精彩亮相IOTE国际物联网展
                  </div>
                </div>
                <p @click="goToPreviousPage" plain class="turnOut">返回列表</p>
              </div>
            </div>

            <!--***--公司动态-新增1--***-->
            <div v-if="thisSolutionName==='工业物联网软硬件制造商——蓉视通科技精彩亮相IOTE国际物联网展'" class="solutionForList">
              <div class="newsListHeaderBox">
                <p>{{ thisSolutionName }}</p>
                <span>时间：2022年11月18日</span>
                <span>作者：杨宇</span>
                <span>来源：蓉视通科技</span>
              </div>
              <!--      新闻内容      -->
              <div class="newsListContentBox">
                <div class="dynamicAdd01">
                  <p>
                    第十八届IOTE国际物联网展于2022年11月15日至17日在深圳国际会展中心（宝安新馆）17号馆盛大开展。本次展会完整覆盖了物联网上下游产业链，涵盖物联网感知层、网络传输层、运算与平台层以及应用层，汇聚了400+家品牌参展商，举行了15场+同期会议，展示了2022最新的物联网技术产品及解决方案，共同探讨未来物联网发展新趋势和新方案。蓉视通科技有限公司（简称“蓉视通科技”）作为工业物联网产业链上重要的供应商受邀参展。
                  </p>
                  <h4>蓉视通科技亮相展会现场</h4>
                  <p>
                    此次展会，蓉视通科技携轨道式巡检机器人、4G/5G路由器、DTU等硬件产品参展，此外还向观众展示了定制化的智慧管廊综合管理系统，全力为客户提供全方位的智慧化解决方案。在展会现场，吸引了行业人士驻足观展与咨询。
                  </p>
                  <div class="centerDiv">
                    <div>
                      <img :src="baseUrl+'imges/news/newsAdd01/img.png'" alt="">

                    </div>
                    <div>
                      <img :src="baseUrl+'imges/news/newsAdd01/img_1.png'" alt="">

                    </div>
                  </div>

                  <h4>工业物联网软硬件制造商</h4>
                  <h4 style="font-size: small;text-indent: 1em;">硬件产品——轨道式巡检机器人</h4>
                    <div>
                      <img :src="baseUrl+'imges/news/newsAdd01/img_2.png'" alt="">
                    </div>
                    <div>
                      <img :src="baseUrl+'imges/news/newsAdd01/img_3.png'" alt="">
                      <p class="label">巡检机器人管理平台</p>
                    </div>
                  <p>
                    蓉视通科技自主研发的轨道式巡检机器人融合了AI图像处理、5G、高精度定位等新兴技术，通过独有专利技术—移动物联网感知载具，搭载高清摄像头、各类感知设备，实现自动巡检、智能识别、环境监测等功能，可应用于市政地下管廊巡检、数据机房巡检、养殖巡检、配电房巡检等应用场景，提升巡检效率，促进管理模式升级。
                  </p>
                  <h4 style="font-size: small;text-indent: 1em;">硬件产品——4G/5G工业路由器、DTU</h4>
                  <div class="centerDiv">
                    <div>
                      <img :src="baseUrl+'imges/news/newsAdd01/img_4.png'" alt="" style="width: auto">
                      <p>5G CPE</p>
                    </div>
                    <div>
                      <img :src="baseUrl+'imges/news/newsAdd01/img_5.png'" alt="" style="width: auto">
                      <p>DTU-Z0115</p>
                    </div>
                  </div>

                  <p>
                    路由器支持RS232、RS485传输、VPN链接功能，APN拨号，支持Modbus协议，并具有云平台管理等功能。适用于M2M行业，如工业自动化、自助终端、大型机械设备数据采集、机房/环境监控，以及各类5G组网场景。
                  </p>

                  <h4 style="font-size: small;text-indent: 1em;">软件集成系统——智慧管廊综合管理系统</h4>
                  <div class="ImgBox">
                    <div class="centerDivBox">
                      <div class="bigImg">
                        <img :src="baseUrl+'imges/news/newsAdd01/img_6.png'" alt="">
                        <p>智慧管廊综合管理系统大屏</p>
                      </div>
                      <div class="rightImgBox PC">
                        <div class="textBox">
                          <img :src="baseUrl+'imges/news/newsAdd01/img_10.png'" alt="">
                          <p>轴剖面图</p>
                        </div>
                        <div class="textBox">
                          <img :src="baseUrl+'imges/news/newsAdd01/img_11.png'" alt="">
                          <p>管线俯视图</p>
                        </div>
                        <div class="textBox">
                          <img :src="baseUrl+'imges/news/newsAdd01/img_12.png'" alt="">
                          <p>单条管线追踪图</p>
                        </div>
                      </div>
                      <div class="rightImgBox Mobile">
                        <div class="textBox">
                          <img :src="baseUrl+'imges/news/newsAdd01/img_7.png'" alt="">
                          <p>轴剖面图</p>
                        </div>
                        <div class="textBox">
                          <img :src="baseUrl+'imges/news/newsAdd01/img_8.png'" alt="">
                          <p>管线俯视图</p>
                        </div>
                        <div class="textBox">
                          <img :src="baseUrl+'imges/news/newsAdd01/img_9.png'" alt="">
                          <p>单条管线追踪图</p>
                        </div>
                      </div>
                    </div>
                  </div>


                  <p>
                    智慧管廊综合管理系统是一套基于公共管廊三维可视化模型而构建的完整性管理和业务流管理系统，可应用于化工厂/化工园区管廊管理、城市地下综合管网及燃气管网管理，实现对公共管廊的综合、统一动态管理，进而实现公共管廊的智慧运营和科学管理。
                  </p>

                  <h4>关于蓉视通科技</h4>
                  <p>蓉视通科技是一家专注于工业物联网领域的国家高新技术企业。公司以安全管控和智能管理业务为核心，致力于提升工业行业的数字化与智能化水平。未来，蓉视通将持续把握物联网发展的风向标，力争成为一家在工业物联网领域有影响力的企业。</p>
                  <p>2022年第十八届国际物联网展（深圳）已圆满落幕，蓉视通科技也将一如既往地致力于开发工业物联网领域的软硬件产品，为工业企业数字化、智能化转型提供更多产品和更好的解决方案！</p>

                </div>

              </div>
              <!--      阅读完成      -->
              <div class="newsListFooter">
                <div class="upDown">
                  <div class="upDown_Box" @click="goNewsList('蓉视通科技——“基于三维可视化的公共管廊智慧管理系统”成功入选2022年智慧化工园区适用技术目录')">
                    上一篇：蓉视通科技——“基于三维可视化的公共管廊智慧管理系统”成功入选2022年智慧化工园区适用技术目录
                  </div>
                  <div class="upDown_Box" @click="goNewsList('蓉视通科技：巡检机器人、工业5G路由器、DTU亮相IOTE国际物联网展')">
                    下一篇：蓉视通科技：巡检机器人、工业5G路由器、DTU亮相IOTE国际物联网展
                  </div>
                </div>
                <p @click="goToPreviousPage" plain class="turnOut">返回列表</p>
              </div>
            </div>

            <!--***--公司动态01--***-->
            <div v-if="thisSolutionName==='蓉视通科技：巡检机器人、工业5G路由器、DTU亮相IOTE国际物联网展'" class="solutionForList">
              <div class="newsListHeaderBox">
                <p>{{ thisSolutionName }}</p>
                <span>时间：2021年04月30日</span>
                <span>作者：张虹</span>
                <span>来源：蓉视通科技</span>
              </div>
              <!--      新闻内容      -->
              <div class="newsListContentBox">
                <p>
                  第十五届国际IOTE物联网展于2021年4月21日至23日在上海世博展览馆举行。展会上汇集了全球700+家展商，针对物联网全产业链进行了完整的展示，涵盖物联网感知层、网络传输层、运算与平台层，以及应用层。蓉视通科技作为工业物联网产业链上重要的供应商受邀参展。</p>
                <img :src="baseUrl+'imges/news/news01/news01.png'" alt="">
                <p>蓉视通科技向广大观展终端客户和集成商展示了自主研发的轨道式巡检机器人、工业级5G路由器和DTU等产品。凭借产品与市场需求的高度契合，吸引了众多行业人士驻足观展、问询。</p>
                <img :src="baseUrl+'imges/news/news01/news02.png'" alt="">
                <img :src="baseUrl+'imges/news/news01/news03.png'" alt="">
                <p>
                  巡检管理一直是企业生产、运维过程中最为重要的工作之一，然而巡检现场环境的复杂性及人工巡检的局限性，都加剧着巡检工作的难度，成为了企业发展的重要难点。蓉视通科技巡检机器人实现了多模式巡检，环境监测，智能行为分析并搭载了智慧巡检平台，可应用于交通巡检、化工园区巡检，仓库巡检，电力巡检，煤矿巡检、农业巡检等场景，提升巡检效率，促进管理模式升级。</p>
                <img :src="baseUrl+'imges/news/news01/news04.png'" alt="">
                <img :src="baseUrl+'imges/news/news01/news05.png'" alt="">
                <p>
                  同时，作为5G时代IoT新连接途径，蓉视通RE-500工业级5G路由器获得了众多工业行业观众的关注，其中不乏智慧医疗，智慧交通，智慧物流、智慧工厂等领域的专业人士。RE-500工业级5G路由器由蓉视通科技研发团队自主研发，具有完备智能的软件功能和全工业级硬件设计，实现了高速率、低时延安全数据传输，被广泛应用于各类工业场景。</p>
                <img :src="baseUrl+'imges/news/news01/news06.png'" alt="">
                <p>2021年是迈入“十四五”新时期的开局年，成都蓉视通科技牢牢把握发展风向标，致力于提升工业行业的数字化和智能化水平，深度了解客户需求，解决客户的实际问题，助力工业企业智慧发展。</p>
              </div>
              <!--      阅读完成      -->
              <div class="newsListFooter">
                <div class="upDown">
                  <div class="upDown_Box" @click="goNewsList('工业物联网软硬件制造商——蓉视通科技精彩亮相IOTE国际物联网展')">
                    上一篇：工业物联网软硬件制造商——蓉视通科技精彩亮相IOTE国际物联网展
                  </div>
                  <div class="upDown_Box" @click="goNewsList('蓉视通科技荣获成都2020年度5G创新应用优秀项目奖')">
                    下一篇：蓉视通科技荣获成都2020年度5G创新应用优秀项目奖
                  </div>
                </div>
                <p @click="goToPreviousPage" plain class="turnOut">返回列表</p>
              </div>
            </div>

            <!--***--公司动态02--***-->
            <div v-if="thisSolutionName==='蓉视通科技荣获成都2020年度5G创新应用优秀项目奖'" class="solutionForList">
              <div class="newsListHeaderBox">
                <p>{{ thisSolutionName }}</p>
                <span>时间：2021年03月23日</span>
                <span>作者：张虹</span>
                <span>来源：成都蓉视通科技有限公司</span>
              </div>
              <!--      新闻内容      -->
              <div class="newsListContentBox">
                <p> 2021年3月18日下午，“2020年度成都5G产业交流活动”在菁蓉汇隆重举行。本次交流活动是在成都市经济和信息化局、高新区新经济发展局指导下，由成都信息通信产业促进会（成都5G产业联盟）主办。</p>
                <p> 成都蓉视通科技有限公司凭借”5G轨道式智能巡检机器人“获得5G创新应用优秀项目奖。</p>
                <img :src="baseUrl+'imges/news/news02/pic01.png'" alt="">
                <!--              2021/06/04新添样式-->
                <span class="news03TitleIcon">5G轨道式智能巡检机器人研发背景</span>
                <p>
                  巡检工作是工业企业管理的难点，也是痛点。目前我国工业产业巡检存在巡检工作任务不明确，缺少专业指标模型建设指导，检查内容不完整等问题。同时也存在人工成本高，部分巡检工作存在危险性，数据报送环节臃肿数据报告质量低等弊端。针对这一系列问题，蓉视通科技顺应数字化趋势，在深度考究行业客户应用需求后，结合5G、物联网、AI、大数据、图像处理等新兴技术，通过搭载各类摄像、感知设备，研发出轨道式智能巡检机器人，取得两项发明专利及数十余项实用新型发明专利。</p>
                <span class="news03TitleIcon">5G轨道式智能巡检机器人功能特点</span>
                <p>移动视频图像采集、环境探测及检测监查、自动避障功能、高级别防爆功能、大屏展示功能、远程升级与诊断、自动生成巡检报告、异常报警。</p>
                <img :src="baseUrl+'imges/news/news02/pic02.png'" alt="">
                <span class="news03TitleIcon">应用场景</span>
                <img :src="baseUrl+'imges/news/news02/pic03.jpg'" alt="">
                <span class="news03imgIcon">5G轨道式智能巡检机器人+农业养殖</span>

                <img :src="baseUrl+'imges/news/news02/pic04.png'" alt="">
                <span class="news03imgIcon">5G轨道式智能巡检机器人+市政地下环廊</span>

                <img :src="baseUrl+'imges/news/news02/pic05.png'" alt="">
                <span class="news03imgIcon">5G轨道式智能巡检机器人+仓储库</span>

                <img :src="baseUrl+'imges/news/news02/pic06.png'" alt="">
                <span class="news03imgIcon">5G轨道式智能巡检机器人+煤矿</span>

                <img :src="baseUrl+'imges/news/news02/pic07.png'" alt="">
                <span class="news03imgIcon">5G轨道式智能巡检机器人+化工园</span>

                <img :src="baseUrl+'imges/news/news02/pic08.png'" alt="">
                <span class="news03imgIcon">5G轨道式智能巡检机器人+交通</span>

                <h4>社会效益</h4>
                <p>
                  因为工业生产环境的特殊性和复杂性，引入巡检机器人是工业巡检的发展趋势。从安全生产方面而言，提高了安全生产系数，将危险抑制在源头阶段，减少企业损失；从巡检效率方面而言，机器人巡检代替人工巡检，巡检的完整度和精准度都得到极大的提高，同时降低了巡检人员成本。随着“十四五”规划新进程的推进，工业产业在数字化、智能化方面也将提到快速发展，5G巡检机器人将成为推动智能化发展的重要力量。</p>
              </div>
              <!--      阅读完成      -->
              <div class="newsListFooter">
                <div class="upDown">
                  <div class="upDown_Box" @click="goNewsList('蓉视通科技：巡检机器人、工业5G路由器、DTU亮相IOTE国际物联网展')">
                    上一篇：蓉视通科技：巡检机器人、工业5G路由器、DTU亮相IOTE国际物联网展
                  </div>
                  <div class="upDown_Box" @click="goNewsList('公共管廊新国标:园区物料“动脉”数字化建设将提速')">
                    下一篇：公共管廊新国标:园区物料“动脉”数字化建设将提速
                  </div>
                </div>
                <p @click="goToPreviousPage" plain class="turnOut">返回列表</p>
              </div>
            </div>

            <!--***--公司动态03--***-->
            <div v-if="thisSolutionName==='公共管廊新国标:园区物料“动脉”数字化建设将提速'" class="solutionForList">
              <div class="newsListHeaderBox">
                <p>{{ thisSolutionName }}</p>
                <span>时间：2019年04月11日</span>
                <span>作者：徐岩</span>
                <span>来源：中国化工报</span>
              </div>
              <!--      新闻内容      -->
              <div class="newsListContentBox">
                <p>随着化工行业的园区化发展，公共管廊的管理越来越受到重视。4月1日，公共管廊管理规程(GB/T
                  36762-2018)正式开始施行。新国标对公共管廊的安全管理、巡检管理、应急管理和数字化管理等方面提出了更高的要求。顺应新国标的要求，成都蓉视通科技开发出智慧管廊管理系统，为公共管廊管理的安全管理、应急管理和数字化管理提供了系统的解决方案。</p>
                <h4>风险管控机制有待完善</h4>
                <p>
                  成都蓉视通科技有限公司常务副总经理马兴乐告诉记者，公共管廊是化工园区生产过程中进行物料输送的载体，它衔接不同装置区，贯穿生产流程的上游、中游到下游各个环节进行介质输送。可以说，公共管廊是化工园区生产的大动脉，在化工园区的生产经营活动中起着举足轻重的作用。
                  马兴乐表示，公共管廊具有分布区域广、延伸距离长、管道数量多，输送介质种类多、聚集度高、危险性大的特点，同时，当前公共管廊信息化建设滞后、智能化管理手段不足，风险和应急管理机制不完善，导致安全管理难度大，面临的安全形势严峻，因此，为公共管廊提供数字化、智能化管理体系就显得尤为必要。</p>
                <p>
                  “目前，园区公共管廊信息化建设整体较为滞后，位置信息、属性信息、管理信息等数据不完善、不系统，尚未建立完整有序的信息共享和联动系统，给安全管理和应急调度工作造成了很大的影响。比如，对管线的改造及新铺设管线不能及时更新图纸，图纸、断面图更新目前依靠设计提供的CAD图纸进行查找，且各主项间的管线号存在差异，无法快速准确查找相关信息。而且，目前化工管廊的安全管理主要依靠人工巡检，存在巡检效率低、工作量大、巡检有大量盲区和死角等问题，缺乏对安全事故的预防性。”马兴乐说。</p>
                <h4>新国标明确数字化建设要求</h4>
                <p>2018年9月17日，由上海化学工业区发展有限公司、中国石油和化学工业联合会等单位联合起草的公共管廊管理规程(GB/T
                  36762-2018)由国标委员会发布，并于今年4月1日正式开始执行。新国标进一步规范和指导我国化工园区公共管廊的建设和管理，是推动国内化工园区规范化建设的一个重要里程碑。</p>
                <p>
                  据介绍，该标准总结了上海化学工业区公共管廊10多年来在建设、管理、运营等方面的经验，规定了化工园区公共管廊管理的职责、规划和设计、建设、运行、和事故与应急，适用于化工园区内输送化学品、蒸汽和污水等厂际管道的公共管廊管理。</p>
                <p>
                  记者注意到，新国标对化工园区公共管廊数字化管理和安全风险防控方面提出了明确的要求和建议。其中包括：管理单位宜对公共管廊实行数字化管理，综合利用地理信息系统等信息技术和各类业务平台，形成综合管理系统，系统中应含有管廊管道数据库日常管理和应急管理等基础功能;管理单位宜选择光纤传感、视频监控、周界入侵报警等技术及以上多种技术构成的技术体系作为公共管廊安全预警系统减少公共管廊的风险;管理单位宜将完整性管理纳入管廊管理的过程，结合数字化管理，建立完整性管理数据库，定期进行风险评价;管理单位和使用单位宜结合数字化管理、完整性管理等手段，构建基于大数据的应急与事故管理系统。</p>
                <p>“随着化工企业园区化发展和新国标的出台，利用新型科学技术手段加强智能监测与管控已经成为化工园区公共管廊管理的趋势。” 马兴乐说。</p>
                <h4>智慧管廊方案打造行业标杆</h4>
                <p>顺应数字化发展的趋势，成都蓉视通科技开发了智慧管廊管理系统，为公共管廊管理的安全管理、应急管理和数字化管理的规范提供了系统的解决方案。</p>
                <p>
                  据介绍，成都蓉视通科技有限公司开发的智慧管廊管理系统，利用先进的物联网、信息科学、传感监测等技术，通过信息集成、实时监测、智能巡检、应急管理等内容，以实现对公共管廊进行安全管控和智能管理的目标。目前这一系统已在万华化学等公司实现应用，并取得了良好的效果。</p>
                <p>
                  马兴乐告诉记者，万华化学烟台生产基地的公共管廊规模大、数量多、距离长，在日常管理和巡检工作中，需要投入大量的人力和物力。为了提高公共管廊的管理效率和安全管理水平，在借鉴国内外公共管廊信息化建设先进经验的基础上，经过考察和验证，由成都蓉视通科技开发实施，探索出了适用于其自身生产业务逻辑的解决方案，目前已经建成并投入运营。</p>
                <p>
                  该系统实现了针对化工园区公共管廊的动态安全监测、数字化管理、智能巡检和应急管理等管理目标。通过部署各类监测传感器(如气体探测器、测温光纤、视频监控等)和接入各类管道运行数据(如流量、压力等)，对运行中的管廊状态进行实时动态监测和安全管控;建立完整化、结构化的管廊信息管理体系，在信息化的基础上切实优化管廊管理模式;运用GIS和三维技术，实现数字化、可视化和智能化管理;通过智能人工巡检系统和智能机器人巡检系统提高巡检效率、提升巡检效果，降低人工巡检工作量；兼容应急管理平台和调度控制。</p>
              </div>
              <!--      阅读完成      -->
              <div class="newsListFooter">
                <div class="upDown">
                  <div class="upDown_Box" @click="goNewsList('蓉视通科技荣获成都2020年度5G创新应用优秀项目奖')">
                    上一篇：蓉视通科技荣获成都2020年度5G创新应用优秀项目奖
                  </div>
                  <div class="upDown_Box" @click="goNewsList('地下综合管廊建设提速')">
                    下一篇：地下综合管廊建设提速
                  </div>
                </div>
                <p @click="goToPreviousPage" plain class="turnOut">返回列表</p>
              </div>
            </div>

            <!--***--新闻资讯-新增1--***-->
            <div v-if="thisSolutionName==='地下综合管廊建设提速'" class="solutionForList">
              <div class="newsListHeaderBox">
                <p>{{ thisSolutionName }}</p>
                <span>时间：2022年10月13日</span>
                <span>作者：/</span>
                <span>来源：中国政府网</span>
              </div>
              <!--      新闻内容      -->
              <div class="newsListContentBox">
                <div class="daodu">
                  <h4>导读</h4>
                  <p>今年上半年，全国在建综合管廊项目68个，规划建设规模285公里，预算总投资额294亿元，累计形成廊体33公里，完成投资26亿元。</p>
                </div>

                <br>
                <p>
                  地下综合管廊是指在城市地下用于集中敷设电力、通信、广播电视、给水、排水、热力、燃气等市政管线的公共隧道。在地下综合管廊中，市政管线有序放置，科学管理，不仅可以极大方便市政管线的维护和检修，还能有效利用道路下的空间，节约城市用地，美化城市环境。与此同时，推进地下综合管廊建设能有效发挥投资拉动作用，为推动经济增长贡献力量。
                </p>
                <p>
                  住房和城乡建设部新闻发言人王胜军近日介绍，今年上半年，全国在建综合管廊项目68个，规划建设规模285公里，预算总投资额294亿元，累计形成廊体33公里，完成投资26亿元。
                </p>
                <h4>综合效益逐步显现</h4>
                <p>
                  推进城市地下综合管廊建设是加快补齐地下基础设施短板的重要内容。推进城市地下综合管廊建设，统筹各类市政管线规划、建设和管理，解决反复开挖路面、架空线网密集、管线事故频发等问题，有利于保障城市安全、完善城市功能、美化城市景观、促进城市集约高效和转型发展，有利于提高城市综合承载能力和城镇化发展质量，有利于增加公共产品有效投资、拉动社会资本投入、打造经济发展新动力。
                <p>
                  地下综合管廊建设稳当前、利长远，一次性投资大，长期效益也大，具有多方面重要作用。王胜军介绍，我国地下综合管廊建设从2015年开始试点，到今年6月底，279个城市、104个县累计开工建设管廊项目1647个、长度5902公里，形成廊体3997公里。
                </p>
                <p>
                  住房和城乡建设部城市建设司一级巡视员邢海峰介绍，2015年至2018年，住房和城乡建设部与财政部共同组织开展中央财政支持综合管廊试点工作，先后确定2批共25个试点城市，带动全国探索综合管廊建设，推动从“该不该干”向“怎么干”的认识转变，逐步扭转“重地上、轻地下”的现象。
                </p>
                <p>
                  近几年，一些城市在应对台风、内涝等方面，综合管廊的作用已初步显现。邢海峰介绍，综合管廊实现对入廊管线的动态监测，日常检修更加便捷高效，提高了管线安全运行水平，提升了城市安全保障和灾害应对能力。
                </p>
                <p>
                  “马路拉链”问题被广大群众诟病多年，每类地下管线的维护修理，都需要分别开挖城市道路，反复开挖不仅影响城市景观，给出行也带来很大影响。将各类管线统一纳入地下综合管廊，可最大限度地避免道路反复开挖。
                </p>
                <p>
                  综合管廊还促进了集约高效利用土地资源。邢海峰介绍，综合管廊减少了架空线和管线直埋敷设的用地需求，能有效节省土地空间；释放的土地资源收益回报还能为偿还建设贷款提供有利条件。据测算，仅25个试点城市5000余公里的高压架空线入廊，就增加了2800公顷可开发建设用地。
                </p>
                <h4>投资拉动效应明显</h4>
                <p>
                  综合管廊建设不仅是城市建设的重要内容，也能够在拉动经济增长方面发挥作用。近期国务院提出稳住经济大盘33条措施，其中之一就是综合管廊建设。综合管廊建设还有效带动了就业。邢海峰介绍，开展综合管廊试点工作以来，按照每50万元投资提供1个就业岗位计算，2015年以来累计提供就业岗位120万个。
                </p>
                <p>
                  随着综合管廊在各地广泛开展试点，企业参与综合管廊建设的积极性大幅提升。
                </p>
                <p>
                  中建一局党委副书记、总经理左强介绍，“十三五”期间，中建一局在13座城市建设20余项地下综合管廊项目，包括北京城市副中心综合管廊、全国首个老旧小区地下综合管廊示范工程——住建部三里河九号院管廊工程、国内下穿高速公路断面最大的北京新机场综合管廊工程等，获得30余项管廊施工专利和工法，积累了丰富的管廊施工经验。
                </p>
                <p>
                  左强介绍，中建一局建设的北京城市副中心地下综合管廊投入使用后，将成为国内断面最大的地下综合管廊。包括自来水管、供电电缆、通信电缆、真空垃圾管道等8大类18种市政管线将被集中起来，分舱归位，有序住进地下宽敞的“集体宿舍”。这也是一个会“呼吸”的管廊，通过集水井收集雨水，经过吸水、蓄水、渗水、净水的过程，在需要时将雨水重新抽上来，用于灌溉和冲洗厕所，从而有效利用雨水，赋予城市“水弹性”。作为城市副中心基础设施体系的重要组成部分，综合管廊的建设将大大提升城市副中心的环境承载力。
                </p>
                <p>
                  王胜军介绍，近期，住房和城乡建设部又初步梳理出两批重点项目清单，包括已完成开工前期准备的项目和部分在建项目，资金需求量超过2000亿元。
                </p>
                <h4>
                  破解难题协同推进
                </h4>
                <p>
                  尽管综合管廊试点工作取得一定成效，但不可否认的是，我国综合管廊建设还处于初级发展阶段，存在制度标准不够完善、规划建设缺乏统筹、部分管廊入廊率偏低、建设运维资金压力大等不少困难和问题。
                </p>
                <p>
                  如何更好推进综合管廊建设？近日，住房和城乡建设部、国家发改委联合发布《“十四五”全国城市基础设施建设规划》。其中要求，因地制宜推进地下综合管廊系统建设，提高管线建设体系化水平和安全运行保障能力，在城市老旧管网改造等工作中协同推进综合管廊建设。在城市新区根据功能需求积极发展干、支线管廊，合理布局管廊系统，加强市政基础设施体系化建设，促进城市地下设施之间竖向分层布局、横向紧密衔接。
                </p>
                <p>
                  结合城市老旧管网改造统筹推进综合管廊建设是综合管廊建设的重要内容。邢海峰介绍，住房和城乡建设部将指导各地结合城市更新科学编制综合管廊建设规划，因地制宜确定管廊建设类型、规模和时序，在城市新区建设和老旧城区改造中分类施策，统筹各类管线敷设、老旧管网和架空线入地改造，加快完成在建综合管廊，推进已建成的管廊尽快达到设计能力并投入运营。
                </p>
                <p>
                  同时，我国还将进一步完善法规制度和标准规范。加强城镇地下综合管廊规划、建设、运行及管理等。加快完善综合管廊规划、建设、运营维护的相关制度和标准规范，为高质量推进综合管廊建设运营提供保障。
                </p>
                <p>
                  综合管廊建设资金压力如何破解？《“十四五”全国城市基础设施建设规划》中提出多渠道筹措资金推进城市基础设施建设。要求区别相关建设项目的经营性与非经营性属性，建立政府与社会资本风险分担、收益共享的合作机制，采取多种形式，规范有序推进政府和社会资本合作（PPP）。推动基础设施领域不动产投资信托基金（REITs）健康发展，盘活城市基础设施存量资产。PPP模式有望在地下综合管廊建设中发挥积极作用。
                </p>
                <p>
                  城市地下管廊的有偿使用，对于地下管廊的可持续推进和发展十分重要。早在2015年，国家发改委、住房和城乡建设部就印发了《关于城市地下综合管廊实行有偿使用制度的指导意见》，要求建立健全城市地下综合管廊有偿使用制度，形成合理收费机制，调动社会资本投入积极性。如今，在全国不少省份及城市发布实施的地下综合管廊地方性政策中，在收费标准实操层面提出具体规范。
                </p>
                <p>
                  江苏省住房和城乡建设厅厅长周岚认为，应进一步健全城市地下综合管廊有偿使用制度，完善入廊收费保障机制和政策，增加刚性约束条款和激励支持内容。这有助于投资人形成清晰的投入回报分析和预期，激发市场和社会力量参与投资建设和运行管理的积极性。
                </p>
                <p>
                  邢海峰介绍，未来还将总结推广25个试点城市在统筹规划建设、投融资机制创新、社会资本引入、共建共享等方面好的经验做法，发挥示范引领作用，引导全国其他地区有序推进综合管廊建设。
                </p>
              </div>
              <!--      阅读完成      -->
              <div class="newsListFooter">
                <div class="upDown">
                  <div class="upDown_Box" @click="goNewsList('公共管廊新国标:园区物料“动脉”数字化建设将提速')">
                    上一篇：公共管廊新国标:园区物料“动脉”数字化建设将提速
                  </div>
                  <div class="upDown_Box" @click="goNewsList('“工业互联网+安全生产”行动计划（2021-2023年）')">
                    下一篇：“工业互联网+安全生产”行动计划（2021-2023年）
                  </div>
                </div>
                <p @click="goToPreviousPage" plain class="turnOut">返回列表</p>
              </div>
            </div>

            <!--***--新闻资讯01--***-->
            <div v-if="thisSolutionName==='“工业互联网+安全生产”行动计划（2021-2023年）'" class="solutionForList">
              <div class="newsListHeaderBox">
                <p>{{ thisSolutionName }}</p>
                <span>时间：2021年02月20日</span>
                <span>作者：/</span>
                <span>来源：科技与信息化司</span>
              </div>
              <!--      新闻内容      -->
              <div class="newsListContentBox">
                <h4>“工业互联网+安全生产”行动计划（2021-2023年）</h4>
                <p>
                  “工业互联网+安全生产”是通过工业互联网在安全生产中的融合应用，增强工业安全生产的感知、监测、预警、处置和评估能力，加速安全生产从静态分析向动态感知、事后应急向事前预防、单点防控向全局联防的转变，提升工业生产本质安全水平。为贯彻落实习近平总书记关于“深入实施工业互联网创新发展战略”“提升应急管理体系和能力现代化”“从根本上消除事故隐患”的重要指示精神，推进《关于深化新一代信息技术与制造业融合发展的指导意见》深入实施，实现发展规模、速度、质量、结构、效益、安全相统一，制定本行动计划。</p>
                <img :src="baseUrl+'imges/news/news04/news04_01.png'" alt="">
                <h4>一、总体要求</h4>
                <p>（一）指导思想</p>
                <p>
                  以习近平新时代中国特色社会主义思想为指导，深入贯彻党的十九大和十九届二中、三中、四中全会精神，贯彻新发展理念，坚持工业互联网与安全生产同规划、同部署、同发展，构建基于工业互联网的安全感知、监测、预警、处置及评估体系，提升工业企业安全生产数字化、网络化、智能化水平，培育“工业互联网+安全生产”协同创新模式，扩大工业互联网应用，提升安全生产水平。</p>
                <p>（二）行动目标</p>
                <p>
                  到2023年底，工业互联网与安全生产协同推进发展格局基本形成，工业企业本质安全水平明显增强。一批重点行业工业互联网安全生产监管平台建成运行，“工业互联网+安全生产”快速感知、实时监测、超前预警、联动处置、系统评估等新型能力体系基本形成，数字化管理、网络化协同、智能化管控水平明显提升，形成较为完善的产业支撑和服务体系，实现更高质量、更有效率、更可持续、更为安全的发展模式。</p>
                <h4>二、重点任务</h4>
                <p>（一）建设“工业互联网+安全生产”新型基础设施</p>
                <p>
                  1.建设网络监管平台。整合现有安全生产数据、平台和系统，构建企业级和行业级工业互联网安全生产监管平台，实现安全生产全过程、全要素、全产业链的连接和监管，具备安全感知、监测、预警、处置、评估等功能，提升跨部门、跨层级的安全生产联动联控能力。</p>
                <p>
                  2.提升数据服务能力。依托国家工业互联网大数据中心，建设“工业互联网+安全生产”行业分中心和数据支撑平台，建立安全生产数据目录，加强数据技术攻关，开发标准化数据交换接口、分析建模以及可视化等工具集，对接重点行业工业互联网安全生产监管平台，开展数据支撑服务，加速安全生产数据资源在线汇聚、有序流动和价值挖掘。</p>
                <p>（二）打造基于工业互联网的安全生产新型能力</p>
                <p>
                  3.建设快速感知能力。分行业制定安全风险感知方案，围绕人员、设备、生产、仓储、物流、环境等方面，开发和部署专业智能传感器、测量仪器及边缘计算设备，打通设备协议和数据格式，构建基于工业互联网的态势感知能力。</p>
                <p>
                  4.建设实时监测能力。制定工业设备、工业视频和业务系统上云实施指南，加快高风险、高能耗、高价值设备和ERP、MES、SCM及安全生产相关系统上云上平台，开发和部署安全生产数据实时分析软件、工具集和语义模型，开展“5G+智能巡检”，实现安全生产关键数据的云端汇聚和在线监测。</p>
                <p>
                  5.建设超前预警能力。基于工业互联网平台的泛在连接和海量数据，建立风险特征库、失效数据库，分行业开发安全生产风险模型，推进边缘云和5G+边缘计算能力建设，下沉计算能力，实现精准预测、智能预警和超前预警。</p>
                <p>
                  6.建设应急处置能力。建设安全生产案例库、应急演练情景库、应急处置预案库、应急处置专家库、应急救援队伍库和应急救援物资库，基于工业互联网平台开展安全生产风险仿真、应急演练和隐患排查，推动应急处置向事前预防转变，提升应急处置的科学性、精准性和快速响应能力。</p>
                <p>
                  7.建设系统评估能力。开发基于工业互联网的评估模型和工具集，对安全生产处置措施的充分性、适宜性和有效性进行全面准确的评估，对安全事故的损失、原因和责任主体等进行快速追溯和认定，为查找漏洞、解决问题提供保障，实现对企业、区域和行业安全生产的系统评估。</p>
                <p>（三）深化工业互联网和安全生产的融合应用</p>
                <p>
                  8.深化数字化管理应用。支持工业企业、重点园区在工业互联网建设中，将数字孪生技术应用于安全生产管理。实现关键设备全生命周期、生产工艺全流程的数字化、可视化、透明化，提升企业、园区安全生产数据管理能力。</p>
                <p>
                  9.深化网络化协同应用。基于工业互联网安全生产监管平台，推动人员、装备、物资等安全生产要素的网络化连接、敏捷化响应和自动化调配，实现跨企业、跨部门、跨层级的协同联动，加速风险消减和应急恢复，将安全生产损失降低到最小。</p>
                <p>
                  10.深化智能化管控应用。依托工业互联网平台，开展重点行业安全管理经验知识的软件化沉淀和智能化应用，加快工艺优化、预测性维护、智能巡检、风险预警、故障自愈、网格化安全管理等工业APP和解决方案的应用推广，实现安全生产的可预测、可管控。</p>
                <p>（四）构建“工业互联网+安全生产”支撑体系</p>
                <p>
                  11.坚持协同部署。加强工业互联网和安全生产在工程、专项和试点工作中的统筹协调，将安全生产作为工业互联网建设和应用的重要任务，系统谋划、统一布局。建设国家、省市县、园区和企业多级协同的工业互联网安全生产监管平台和监测体系，提升工业互联网服务经济运行监测和工业基础监测的能力。</p>
                <p>12.聚焦本质安全。聚焦设计安全、生产安全、服务安全、变更安全等关键环节，通过应用试点，以海量应用加速信息技术产品创新应用，推动生产工艺、测试工具等工业基础能力迭代优化，提升本质安全水平。</p>
                <p>13.
                  完善标准体系。聚焦“工业互联网+安全生产”新技术新模式新业态，落实工业互联网与安全生产标准同规划、同部署、同发展，加快制修订国家标准和行业标准，鼓励社会组织制定团体标准。开发自动化贯标工具，通过贯标推广新技术、新应用，提升安全生产的规范化水平。</p>
                <p>
                  14.培育解决方案。坚持分业施策，围绕化工、钢铁、有色、石油、石化、矿山、建材、民爆、烟花爆竹等重点行业，制定“工业互联网+安全生产”行业实施指南。建设面向重点行业的工业互联网平台，开发安全生产模型库、工具集和工业APP，培育一批行业系统解决方案提供商和服务团队。</p>
                <p>
                  15.强化综合保障。完善国家工控安全监测网络。以试点示范和防护贯标为引领，支持企业工业互联网、工控安全产品和解决方案的开发和应用。落实企业网络安全主体责任，实施工业互联网企业网络安全分类分级管理，提升企业安全防护水平。</p>
                <h4>三、保障措施</h4>
                <p><span class="tipBlue">（一）明确责任分工。</span>工业和信息化主管部门负责工业互联网在安全生产中的应用，组织开发技术和解决方案。应急管理部门负责创新基于工业互联网的安全生产监管方式，加强对企业接入工业互联网安全生产监管平台的管理，建立与行政许可换证挂钩等激励约束机制。双方共同建立“工业互联网+安全生产”工作推进机制，定期通报成果，明确时间进度，强化督促检查。中国工业互联网研究院负责技术开发和数据支撑平台建设和运行，中国安全生产科学研究院负责工业互联网安全生产监管平台建设和运行。工业企业严格落实安全生产主体责任，坚持工业互联网与安全生产同规划、同部署、同发展。
                </p>
                <p><span class="tipBlue">（二）加大支持力度。</span>依托工业互联网创新发展工程等专项加大对“工业互联网+安全生产”方向的资金投入，支持基础共性技术产品研发、公共服务平台建设和解决方案提供商培育。依托现有渠道，争取对企业技改等方面的支持。鼓励地方设立专项，引导企业加大投入，支持开展技术改造，提升工业安全生产的感知、监测、预警、处置和评估能力。
                </p>
                <p><span class="tipBlue">（三）开展试点应用。</span>组织开展“工业互联网+安全生产”试点应用，遴选一批可复制、易推广的园区和企业标杆应用，培育一批解决方案提供商。推动技术创新和应用创新，加快互联网、大数据、人工智能、区块链等新一代信息技术在“工业互联网+安全生产”领域的融合创新与推广应用，探索安全生产管理新方式，推动现场检查向线上线下相结合检查转变、一次性检查向持续监测转变，提升行政管理效率。
                </p>
                <p><span class="tipBlue">（四）加强日常演练。</span>督促企业完善应急预案，加强专兼职应急队伍建设及应急物资装备配备，基于工业互联网实现要素资源的网络化协同和智能化调配，增强应急处置支撑能力。建设应急演练虚拟仿真环境，开展日常培训、线上应急演练和实战演练，提升综合保障能力。
                </p>
                <p><span class="tipBlue">（五）建设人才队伍。</span>开发基于工业互联网的仿真培训考试系统，建设安全生产培训考试智能监控体系，加快专业人才培养。建设“工业互联网+安全生产”人才培养和评价体系，加强实训基地和“新工科”建设，汇聚产学研用优质资源，培养复合型人才队伍。
                </p>
              </div>
              <!--      阅读完成      -->
              <div class="newsListFooter">
                <div class="upDown">
                  <div class="upDown_Box" @click="goNewsList('地下综合管廊建设提速')">
                    上一篇：地下综合管廊建设提速
                  </div>
                  <div class="upDown_Box" @click="goNewsList('智慧医疗和医疗的未来')">
                    下一篇：智慧医疗和医疗的未来
                  </div>
                </div>
                <p @click="goToPreviousPage" plain class="turnOut">返回列表</p>
              </div>
            </div>

            <!--***--新闻资讯02--***-->
            <div v-if="thisSolutionName==='智慧医疗和医疗的未来'" class="solutionForList">
              <div class="newsListHeaderBox">
                <p>{{ thisSolutionName }}</p>
                <span>时间：2021年02月18日</span>
                <span>作者：廖新波</span>
                <span>来源：健康界</span>
              </div>
              <!--      新闻内容      -->
              <div class="newsListContentBox">
                <h4>政策：智慧医疗被列入《新一代人工智能发展规划》</h4>
                <p>
                  目前中国人工智能发展已经进入了一个新阶段，也许现在正是我们赶超世界水平的一个弯道超车的关键节点。人工智能不仅是当下国际竞争的新焦点，也可能成为我国未来经济发展的一个新引擎。2017年国务院正式印发的《新一代人工智能发展规划》（以下简称《规划》），分阶段设立了到2030年的“三步走”目标：到2020年我国人工智能技术和应用与世界先进水平同步；到2025年人工智能基础理论实现重大突破；到2030年部分技术和应用达到世界的领先水平，成为世界人工智能创新中心。该《规划》中，已经把有关智慧医疗的两大项列入了新一代人工智能规划当中，《规划》也促使“智慧医疗”在2018年迎来爆发。</p>
                <h4>挑战：传统医疗向智慧医疗转变面临的三大挑战</h4>
                <p>要适应新一带人工智能发展规划里面提出的智能医疗，我认为医疗行业目前主要面临了三大挑战。</p>
                <p><span class="tipBlue">首先是资源互通、数据共享和系统链接方面。</span>这些对医疗行业的发展起到至关重要的作用。比如现在国内很多企业正在研发智能病理诊断系统，研发这套系统，需要大量的临床数据。如果我们可以做到信息共享，我相信会很大程度上推进该产品的研发，加快医学界“新助理”的诞生，同时帮助缓解医生短缺的问题。信息共享也不仅仅是令智能病理诊断系统的研发收益，未来还会加速更多适应临床医疗的产品落地。
                </p>
                <img :src="baseUrl+'imges/news/news05/zx01001.png'" alt="">
                <p>
                  但很可惜，到了今天，许多医院还在使用传统的数据库，大部分原生医疗数据都还只是静静的躺在那里，没有被有效整合与利用。但随着大数据、云计算、区块链等技术的成熟，这些数据库逐渐云端化，在保障病人隐私和医生权限的前提下，医疗资源就可以走上互联互通之路。</p>
                <p><span class="tipBlue">第二是政策和伦理方面。</span>《规划》的最后一点表示社会各界媒体应该及时做好舆论引导，更好应对人工智能发展可能带来的社会、伦理和法律等挑战。我之所以说到这一条，是因为其实人工智能在政策和伦理上，尤其是在传统医疗领域面临的挑战非常大。
                </p>
                <p>
                  现在很多医生相信智能医疗的准确性，实验证明它总比大多数医生能避免更多的漏诊和误诊。在西京医院曾进行过6次人与机器人AIphaGo的对决，我作为主持嘉宾参加了第5次对决，亲眼目睹在脑膜炎的诊断上，人远远比不上机器人。参赛人员的准确率没有超过41%，而机器人至少有70%的准确率，因为机器人可以快速的连续学习。这个结果令人兴奋，说明技术发展的步伐很快。但是人工智能给出的医疗报告是否真的可以作为诊断的依据呢？你相信吗？很多病人不会相信，这就是个伦理的问题。如何让百姓普遍接受和推广应用人工智能治疗新模式、新手段是目前的一大挑战。我想最应该去推广的人应该是受益的病人和那些已经掌握一些技术的
                  <span class="tipBlue">医学专家</span>们。补充一点，如果这些专家们不拥抱智慧医疗，不仅他们没有起到帮助智慧医疗发展的作用，他们也将会失去目前自身的优势。</p>
                <img :src="baseUrl+'imges/news/news05/zx01002.png'" alt="">
                <p><span class="tipBlue">第三是“人工智能+医疗”创新产品的经济价值。</span>若要实现智慧医疗落地，光有创新是不行的，还需要有经济价值。比如智能穿戴产品虽然是一个较大的创新，但是在医疗、健康方面并没有产生可观的经济价值，或许智能穿戴产品还需要升级改造。现在人工智能技术创新有很多优秀的创意，有但有些其实是没有用的，我们应该合理判断这些创新产品的价值。创新的过程需要注意三大要素：创新、价值和市场。只有这三者合一，形成一个运营闭环，创新产品才可以发挥真正的作用。也就是说如果产品只有创新的服务模式，却没有盈利模式，那它依然不可以产生价值。比如现在很多医疗APP，就面临这样的问题，他们既做线上服务，又想办实体医疗机构，但事实上小型医疗机构是很难与传统医疗机构进行竞争的，如何利用互联网的工具进行医疗数据的清洗、整合，然后再开发利用，这才是医疗APP的发展方向。
                </p>
                <img :src="baseUrl+'imges/news/news05/zx01003.png'" alt="">
                <p>总之，我认为智慧医疗的逐步落地，需要先解决资源整合、数据共享，舆论引导，运营闭环等问题，而政府也应该引导相关平台的建设，使智慧医疗健康发展。</p>
                <h4>趋势1：智慧医疗的未来</h4>
                <p>
                  人工智能在智慧医疗目前主要有三个方面的应用：一是虚拟助理系统，帮助医生、护士、技术员做诊断护理；二是大数据的集成、挖掘，尤其是数据端的开放，安全的问题等等；三是智能影像，这个是目前做的最成功的。人工智能影像可以说比人工准确的多，它基本不会漏诊。</p>
                <img :src="baseUrl+'imges/news/news05/zx01004.png'" alt="">
                <p>
                  现在全球社会正在进入大健康科技革命，今后智能医疗将会有很大的市场。从中美医疗资源分布情况对比来看，中国医疗资源的95%都放在了医院服务，而美国的这个比例仅为19%。我认为未来中国会在大健康的预防疾病、疾病康复、养老、家庭保健、医疗器械以及健康<span
                    class="tipBlue">风险管理</span>等方面调动更多的医疗资源，市场前景可观。</p>
                <p>接下来我向大家展开介绍几种智慧医疗未来的应用方向：</p>
                <p><span class="tipBlue">第一是健康养老机器人。</span>现在日本已经研发出一种养老机器人，它可以根据老人发出的语音要求做出反应。比如口语命令它扶老人起床，它就会走到床边，根据老人身高和床的高度伸出机器手把他抱起来，然后送他到指定地方，例如客厅、厕所，甚至将他扶上轮椅。未来，健康养老机器人深度开发将是智慧医疗发展的方向之一。
                </p>
                <img :src="baseUrl+'imges/news/news05/zx01005.png'" alt="">
                <p><span class="tipBlue">第二是模拟医学系统。</span>目前，美国已经把模拟医学成立为一个单独的学科，广东省也在努力推动这个学科的建设。举一个例子，比如我们可以通过计算机构图建立三维数据模型，把病人的检查影像图、心电图等个人检查数据输入进模拟系统，模拟逼真的手术的环境，医生就可以模拟手术现场可能遇到的情况，如血管切什么地方安全、麻药打多少计量合适等。模拟医学系统会相应给出仿真反馈，为医生的实际手术情况做一个预测，从而帮助医生更准确的选择治疗措施。未来，当此类技术更加成熟之后，我们需要把这些技术从三甲医院向基层医院推广，真正实现医疗下沉。
                </p>
                <img :src="baseUrl+'imges/news/news05/zx01006.png'" alt="">
                <p><span class="tipBlue">第三是智能多学科会诊未来也是重点发展趋势之一。</span>第三，智能多学科会诊可以在线上帮助部分病人进行基本的疾病筛查，指导 <span
                    class="tipBlue">合理用药</span>，判断病人是否需要送医院治疗，以缓解当今医疗资源紧张的局面。</p>
                <img :src="baseUrl+'imges/news/news05/zx01007.png'" alt="">
                <p>
                  我认为在未来，在医疗领域有六大技术会影响传统技术的改变：人工智能、机器人、区块链、数据安全可靠性、3D打印技术和医疗大数据。大健康就是要人们回到健康的本源，未来的智慧医疗市场前景十分广阔，可以做的事情非常多，不只是治病过程，就连
                  <span class="tipBlue">医院后勤管理</span>也是，人工智能从方方面面提高对群众的医疗服务品质。</p>
                <h4>趋势2：医疗的未来</h4>
                <p><span class="tipBlue">一是智慧医疗医院系统。</span>智慧医疗将来会应用在医院系统非常多的地方。比如药品、耗品管理系统，解决病房人手不足的医疗安全监控系统等等。</p>
                <img :src="baseUrl+'imges/news/news05/zx01008.png'" alt="">
                <p><span class="tipBlue">二是卫生防疫领域。</span>比如疾病控制，通过发热门诊数据自动连接和报警，分析病人的主要来源，从而找出病源地，提早采取病源控制措施。当年的SARS查了很久才查到病源，如果有智慧医疗的帮助，传播路线可以清清楚楚的被看到，病源从香港传入就可以很快会被查出了，这就是科技的进步。
                </p>
                <img :src="baseUrl+'imges/news/news05/zx01009.png'" alt="">
                <p><span class="tipBlue">三是卫生监督。</span>现在很多医院的污水处理效果可以说是不及格的，如果我们在污水出口都装上传感器，数据实时监控和公开，在这样的压力下，医院就会自觉去处理污水排放，否则很快就会因此而负上相应的法律责任。如今治理环境污染是个攻坚战，智慧医疗可以起到辅助作用。
                </p>
                <p><span class="tipBlue">四是个人健康系统建立。</span>例如睡眠呼吸暂停综合症检测仪，目前这方面的仪器已经有投入实际应用的。我有一个好朋友，本身比较胖，某天饮酒较多，被呼吸暂停综合症带走了。如果未来有更多类似的仪器产品，全面监测个人的健康情况，甚至有救护能力，或许就可以挽救很多这样的人了。
                </p>
                <img :src="baseUrl+'imges/news/news05/zx01010.png'" alt="">
                <p>在智慧医疗如火如荼的今天，我们也要注意到，<span class="tipBlue">当前市场上很多健康智能产品，没有经过科学论证，它们的权威性、误导性都需要进一步论证</span>。传统医疗模式向大健康模式转变还需要一个过程。未来，我们的医疗系统应该建立健康医疗数据互联互通的共享平台，不仅为普通人提供生命健康管理服务，也改善医生的就业生态、患者就诊生态和慢病管理的生态，同时也传播健康的生活理念。
                </p>
                <img :src="baseUrl+'imges/news/news05/zx01011.png'" alt="">
                <p>生活在在这个创新层出的时代，最可怕的不是缓慢接受创新的人，而是那些嫉妒别人、阻碍发展、不愿意付出的人。愿大家携手努力，共创医疗新时代。</p>


              </div>
              <!--      阅读完成      -->
              <div class="newsListFooter">
                <div class="upDown">
                  <div class="upDown_Box" @click="goNewsList('“工业互联网+安全生产”行动计划（2021-2023年）')">
                    上一篇：“工业互联网+安全生产”行动计划（2021-2023年）
                  </div>
                  <div class="upDown_Box" @click="goNewsList('打造一体高效综合枢纽 陕西推进智慧绿色交通发展')">
                    下一篇：打造一体高效综合枢纽 陕西推进智慧绿色交通发展
                  </div>
                </div>
                <p @click="goToPreviousPage" plain class="turnOut">返回列表</p>
              </div>
            </div>

            <!--***--新闻资讯03--***-->
            <div v-if="thisSolutionName==='打造一体高效综合枢纽 陕西推进智慧绿色交通发展'" class="solutionForList">
              <div class="newsListHeaderBox">
                <p>{{ thisSolutionName }}</p>
                <span>时间：2021年01月19日</span>
                <span>作者：梁璠</span>
                <span>来源：西安日报</span>
              </div>
              <!--      新闻内容      -->
              <div class="newsListContentBox">
                <p>
                  “十四五”时期，是陕西加快交通强省建设的第一个五年。记者从近日召开的全省交通运输工作会议上获悉，预计到“十四五”末，全省铁路营业里程力争达到6500公里以上，其中高速铁路营业里程力争达到1500公里以上;高速公路通车总里程达到7000公里以上，省际出口数量增至29个;全省民用运输机场数量达到8个;力争实现具备条件的建制村物流快递服务全覆盖。</p>
                <h4>构建高品质综合交通网：加快完善以西安为中心的“米”字形高速铁路网</h4>
                <p>
                  巩固铁路枢纽地位，加快完善以西安为中心的“米”字形高速铁路网，铁路建设规模约1200公里。大力实施高速公路效能提升、通道畅通、中心强化、加密连通等4项工程，高速公路建设规模约1800公里。推动国省干线提质增效，努力提升沿黄公路等重点路段服务水平，力争普通国道二级、普通省道三级及以上公路比重分别达到75%、70%。</p>
                <p>
                  优化完善机场布局体系，高标准建成西安咸阳国际机场三期扩建工程，持续提升榆林机场区域枢纽功能，着力推动延安、安康、汉中机场高质量发展，加快建设宝鸡、府谷、定边等支线机场，努力打造覆盖广泛、深度通达的航线网络。加快汉江白河等水电枢纽过船设施建设，尽早畅通汉江安康以下黄金水道;发展特色水运，推动旅游航道、旅游码头建设。</p>
                <h4>打造一体高效综合枢纽：推进西安咸阳国际机场东航站楼等综合客运枢纽建设</h4>
                <p>
                  围绕打造中欧班列(西安)集结中心、西安全球性邮政快递枢纽集群、西安国际航空枢纽等，统筹优化综合运输通道网络和枢纽布局，加快建设一批高效集约、衔接顺畅的综合客货枢纽。重点推进西安咸阳国际机场东航站楼等综合客运枢纽以及西安港等综合货运枢纽建设。加快完善枢纽集疏运体系，畅通“最后一公里”，推动多式联运示范工程创建，提高交通运行效率。</p>
                <h4>推动交通运输转型升级：提高建制村快递服务通达率</h4>
                <p>鼓励发展公铁、空铁、地空等长途旅客联程联运，探索行李联程托运以及联程售票服务，提高客运电子客票率，促进运输服务新模式新业态发展。</p>
                <p>大力发展多式联运等运输组织方式，持续推动降低运输成本。加快农村客运和邮政基础设施建设，完善农村物流和邮政普遍服务体系，提高建制村快递服务通达率。</p>
                <h4>推进智慧绿色交通发展：推进北斗、5G、区块链、大数据等技术在交通运输行业应用</h4>
                <p>
                  加大安全防护、危桥改造等投入，强化养护管理，增强设施耐久性和可靠性，提升设施设备安全性能和本质安全水平。实施创新驱动，推进北斗、5G、区块链、大数据等技术在交通运输行业应用。强化交通新型基础设施建设，大力发展智慧交通。</p>
              </div>
              <!--      阅读完成      -->
              <div class="newsListFooter">
                <div class="upDown">
                  <div class="upDown_Box" @click="goNewsList('智慧医疗和医疗的未来')">
                    上一篇：智慧医疗和医疗的未来
                  </div>
                </div>
                <p @click="goToPreviousPage" plain class="turnOut">返回列表</p>
              </div>
            </div>

          </div>

        </div>
        <!--    右侧---新闻导读    -->
        <div class="sidebar" :style="status==='fixed'?'position: fixed;top: 0;left:'+ SidebarLeft+ 'px':status==='absolution'?'position: absolute; bottom: 0;left:'+ SidebarLeft+ 'px':''">
          <p class="sidebar_title">最热资讯</p>
          <div class="sidebar_Box">
            <div class="sidebar_content" @click="goNewsList('公共管廊新国标:园区物料“动脉”数字化建设将提速')">
              <img :src="baseUrl+'imges/news/sidebarImg1.png'" alt="">
              <p>蓉视通科技巡视机器人、工业5G路由器、DTUIOTE国际检测物联网展</p>
              <span>时间：2021年04月30日</span>
            </div>
            <div class="sidebar_content" @click="goNewsList('蓉视通科技荣获成都2020年度5G创新应用优秀项目奖')">
              <img :src="baseUrl+'imges/news/sidebarImg2.png'" alt="">
              <p>蓉视通科技荣获成都2020年度5G创新应用优秀项目奖</p>
              <span>时间：2021年03月23日</span>
            </div>
            <div class="sidebar_content" @click="goNewsList('“工业互联网+安全生产”行动计划（2021-2023年）')"v>
              <img :src="baseUrl+'imges/news/sidebarImg3.png'" alt="">
              <p>“工业互联网+安全生产”行动计划（2021-2023年）</p>
              <span>时间：2021年02月20日</span>
            </div>
          </div>
        </div>

      </div>
    </div>

    <Footer ref="footer"></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "Solution",
  data() {
    return {
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      //当前新闻名称
      thisSolutionName: this.$route.params.name || "蓉视通科技受邀参加2023石油化工数字化（储运）罐区高峰论坛",
      SidebarTop: 0,
      SidebarHeight: 0,
      SidebarLeft: 0,
      SidebarBottom: 0,
      status: '',
    }
  },
  components: {
    Header,
    Footer
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.SidebarTop = document.getElementsByClassName('sidebar')[0].offsetTop
    this.SidebarLeft = document.getElementsByClassName('sidebar')[0].offsetLeft
    this.SidebarHeight = document.getElementsByClassName('sidebar')[0].offsetHeight
  },
  methods: {
    //返回上一页
    goToPreviousPage() {
      this.$router.push("/news")
    },
    //右侧热点新闻点击事件
    goNewsList(e) {
      this.thisSolutionName = e;
      document.documentElement.scrollTop = 0
    },
    handleScroll(e) {
      this.SidebarBottom = document.getElementById('Footer').getBoundingClientRect().top

      // this.SidebarBottom = this.$refs.footer.getBoundingClientRect().top
      this.scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      if(this.scrollTop>=this.SidebarTop && this.SidebarBottom>this.SidebarHeight){
        this.status = 'fixed'
      } else if(this.SidebarBottom<=this.SidebarHeight){
        this.status = 'absolution'
      } else {
        this.status = ''
      }
    },

  }
}
</script>

<style lang="less" scoped>
#newsList {
  .PC{
    display: block;
  }
  .Mobile{
    display: none;
  }

  .contentBox {
    position: relative;
    .navHead {
      display: block;
      margin: 70px 0;
      width: 100%;
      height: 66px;
      background: #F6F6F6;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      .navNews{
        margin-left: 8vw;
        line-height: 66px;
      }
    }
    .turnHead{
      display: none;
    }

    .newsBox {
      width: 1620px;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      margin: 70px 8vw 60px;
      .rightNews {
        width: 1210px;
        min-height: 900px;
        background: #FAFAFA;
        .rightNews_Box{
          width: 1108px;
          margin: 71px auto 0;
        }
        //新闻头
        .newsListHeaderBox {
          //height: 118px;
          text-align: left;
          border-bottom: 1px solid #D8D8D8;
          padding-bottom: 30px;
          p {
            font-family: Regular;
            font-size: 30px;
            margin-bottom: 27px;
            color: #000000;
          }
          span {
            padding-right: 40px;
            font-family: Regular;
            font-size: 16px;
            color: #454545;
          }

        }

        //  新闻内容
        .newsListContentBox {
          margin-top: 30px;
          text-align: left;
          .daodu{
            position: relative;
            border: 1px solid #0564af;
            h4{
              position: absolute;
              top: -25px;
              left: 10px;
              font-size: 24px;
              font-style: oblique;
              color: #0564af;
              background-color: #fff;
            }
            p{
              color: #0564af;
              margin: 20px 0 10px;
            }
          }
          p {
            font-size: 14px;
            font-family: Regular;
            font-weight: 500;
            line-height: 32px;
            color: #666666;
            opacity: 1;
            text-align: justify;
            text-indent: 2em;
          }

          h4 {
            height: 22px;
            font-size: 16px;
            font-family: Medium;
            font-weight: bold;
            line-height: 32px;
            color: #333333;
            opacity: 1;
            margin-top: 10px;
            margin-bottom: 9px;
          }

          .tipBlue {
            color: blue;
          }

          img {
            width: 100%;
            display: block;
            margin: 30px auto;
          }
          // 公司动态-新增1 的样式
          .dynamicAdd01{
            img{
              width: auto;
            }
            h4{
              font-size: large;
              margin-bottom: 18px;
            }
            .label{
              margin: -20px auto 20px;
              text-align: center;
            }
            // 两张图片组合盒子
            .centerDiv{
              position: relative;
              display: flex;
              justify-content: center;
              margin: 0 auto 30px;
              // 图片文字标签
              div{
                position: relative;
                width: 100%;
                //height: 100%;
                img{
                  //width: auto;
                  width: 100%;
                  height: 87%;
                }
                p{
                  position: absolute;
                  bottom: -30px;
                  left: 50%;
                  transform: translateX(-50%);
                }
              }
            }
            // 多张图片组合盒子
            .ImgBox{
              display: flex;
              justify-content: left;
              margin: 20px 0;
              .centerDivBox{
                position: relative;
                display: flex;
                justify-content: center;
                margin: 0 auto 30px;
                .textBox{
                  position: relative;
                  img{
                    width: 96%;
                    margin: 3px 0 1px 0;
                  }
                  p{
                    width: 0;
                    height: 300px;
                    writing-mode:vertical-lr;
                    position: absolute;
                    top: -20px;
                    right: 0;
                    text-align: left;
                  }
                }
                .bigImg{
                  position: relative;
                  img{
                    width: 100%;
                    margin: -5px 0 10px 0;
                  }
                  p{
                    position: absolute;
                    bottom: -20px;
                    left: 50%;
                    transform: translateX(-50%);
                  }
                }
              }
            }
          }
          //2021/06/04添加样式开始部分
          .news03TitleIcon {
            display: block;
            line-height: 50px;
          }

          .news03TitleIcon:before {
            content: "11";
            color: #4f81bd;
            margin-right: 10px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #4f81bd;
            border: 3px solid #385d8a;
          }

          .news03imgIcon {
            margin: 10px auto;
            width: 80%;
            text-align: center;
            display: block;
          }
          //2021/06/04添加样式结束部分
        }

        //阅读完成
        .newsListFooter{
          display: flex;
          justify-content: space-between;
          margin: 91px 0 70px;
          width: 100%;
          color: #3D3D3D;
          font-family: Regular;
          .upDown{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 90px;
            text-align: left;
            .upDown_Box{
              width: 100%;
              height: 33px;
              font-size: 16px;
              border-bottom: 1px solid #D8D8D8;
              &:hover{
                cursor: pointer;
                color: #0564AF;
                border-bottom: 1px solid #0564AF;
              }
            }
          }
          .turnOut{
            font-size: 16px;
            height: 33px;
            border-bottom: 1px solid #D8D8D8;
            &:hover{
              cursor: pointer;
              color: #0564AF;
              border-bottom: 1px solid #0564AF;
            }
          }
        }
      }

      //右侧新闻导读
      .sidebar{
        display: block;
        width: 390px;
        height: 1025px;
        background-color: #FAFAFA;
        z-index: 1;
        .sidebar_title{
          text-align: left;
          margin: 70px 0 20px 27px;
          font-family: Regular;
          font-size: 24px;
          color: #454545;
        }
        .sidebar_Box{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 336px;
          height: 862px;
          margin: 0 auto;
          .sidebar_content{
            width: 336px;
            height: 274px;
            overflow: hidden;
            text-align: left;
            cursor: pointer;
            &:hover{
              p{
                color: #0564AF;
              }
            }
            img{
              width: 100%;
              height: 177px;
              transition: all .6s;
            }
            img:hover{
              transform: scale(1.1);
            }
            p{
              width: 310px;
              margin-top: 10px;
              font-family: Regular;
              font-size: 16px;
              color: #454545;
            }
            span{
              display: block;
              margin-top: 8px;
              height: 20px;
              font-family: Regular;
              font-size: 12px;
              line-height: 20px;
              color: #999999;
            }
          }
        }
      }

    }
  }
}

//媒体查询适应手机端
@media screen and (min-width: 360px) and (max-width: 750px) {
  html {
    font-size: 140px / 10;
  }
  #newsList{
    .PC{
      display: none;
    }
    .Mobile{
      display: block;
    }

    .contentBox{
      //导航栏
      .navHead{
        display: none;
      }
      //返回列表
      .turnHead{
        display: block;
        width: 100%;
        height: 50px;
        background: #F5F5F5;
        p{
          width: 90%;
          margin: 64px auto 0;
          line-height: 50px;
          text-align: left;
          font-family: Regular;
          font-size: 12px;
          font-weight: normal;
          letter-spacing: 0.05em;
          color: #0564AF;
        }
      }
      //新闻内容
      .newsBox{
        width: 100%;
        margin: 26px auto 0;
        //新闻布局
        .rightNews{
          width: 90%;
          background: #FFFFFF;
          margin: 0 auto;
          .rightNews_Box{
            width: 100%;
          }
          //新闻头
          .newsListHeaderBox {
            width: 100%;
            //height: 140px;
            border-bottom: none;
            padding-bottom: 30px;
            p{
              width: 100%;
              margin-bottom: 20px;
              //height: 73px;
              font-family: Regular;
              font-size: 20px;
              font-weight: normal;
              letter-spacing: 0em;
              color: #000000;
            }
            span{
              width: 100%;
              height: 17px;
              font-family: Regular;
              font-size: 12px;
              font-weight: normal;
              letter-spacing: 0em;
              color: #999999;
            }
            span:nth-of-type(1){
              display: block;
              word-wrap: break-word;
            }
            span:nth-of-type(2):after {
              content: "　";
            }
          }

          //  新闻内容
          .newsListContentBox {
            margin-top: 0;
            .daodu{
              position: relative;
              box-sizing: border-box;
              padding: 0 5px;
              border: 1px solid #0564af;
              h4{
                position: absolute;
                top: -25px;
                left: 10px;
                width: 50px;
                height: 20px;
                font-size: 24px;
                font-style: oblique;
                color: #0564af;
                background-color: #fff;
              }
              p{
                color: #0564af;
                margin: 20px 0 10px;
              }
            }
            img{
              margin: 21px auto;
              width: 100%;
            }
            p{
              width: 100%;
              font-family: Regular;
              font-size: 14px;
              font-weight: normal;
              line-height: 26px;
              letter-spacing: 0em;
              color: #666666;
            }
            h4{
              width: 100%;
              height: 100%;
              font-size: 14px;
              line-height: 26px;
              margin-top: 10px;
              margin-bottom: 20px;
            }
            // 公司动态-新增1 的样式
            .dynamicAdd01{
              img{
                width: 90%;
              }
              .label{
                margin: -10px auto 20px;
                text-align: center;
                transform: translateX(-10px);
              }
              .centerDiv{
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 auto 40px;
                div{
                  position: relative;
                  width: 100%;
                  img{
                    width: 100%;
                  }
                  p{
                    position: absolute;
                    bottom: -20px;
                    left: 50%;
                    transform: translateX(-50%);
                    text-align: center;
                  }
                }
              }
              .ImgBox{
                display: flex;
                justify-content: left;
                margin: 0;
                .centerDivBox{
                  position: relative;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  margin: 0 auto 1px;
                  .textBox{
                    position: relative;
                    img{
                      width: 100%;
                      margin: 0 0 10px 0;
                    }
                    p{
                      position: static;
                      width: 100%;
                      height: auto;
                      writing-mode: horizontal-tb;
                      text-align: center;
                      margin-bottom: 10px;
                      transform: translateX(-10px);
                    }
                  }
                  .bigImg{
                    position: relative;
                    img{
                      width: 100%;
                      margin: 0 0 40px 0;
                    }
                    p{
                      position: absolute;
                      bottom: 10px;
                      left: 50%;
                      transform: translateX(-50%);
                      width: 100%;
                      text-align: center;
                    }
                  }
                  .rightImgBox{
                    width: auto;
                  }
                }
              }

            }

            //2021/06/04添加样式开始部分
            .news03TitleIcon{
              width: 100%;
              height: 100%;
              line-height: 26px;
              margin-top: 10px;
              margin-bottom: 10px;
            }
            .news03TitleIcon:before {
              content: "11";
              color: #4f81bd;
              margin-right: 10px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #4f81bd;
              border: 3px solid #385d8a;
            }

            .news03imgIcon {
              margin: 0 auto;
              width: 100%;
              text-align: center;
              display: block;
            }
            //2021/06/04添加样式结束部分
          }

          //阅读完成
          .newsListFooter{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 91px 0 70px;
            width: 100%;
            height: 100%;
            color: #3D3D3D;
            font-family: Regular;
            .upDown{
              width: 100%;
              height: 90px;
              .upDown_Box{
                width: 100%;
                height: 33px;
                font-family: Regular;
                font-size: 14px !important;
                font-weight: normal;
                letter-spacing: 0em;
                color: #3D3D3D;
                //超过的内容显示省略号
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                border-bottom: 1px solid #D8D8D8;
                &:hover{
                  cursor: pointer;
                  color: #0564AF;
                  border-bottom: 1px solid #0564AF;
                }
              }
            }
            .turnOut{
              width: 179px;
              height: 42px;
              display: flex;
              font-family: Regular;
              font-size: 14px !important;
              font-weight: normal;
              letter-spacing: 0em;
              color: #0564AF;
              flex-direction: column;
              margin: 30px auto 0;
              padding: 8px 60px 14px 60px;
              box-sizing: border-box;
              border: 1px solid #0564AF;
            }
          }

        }
        //新闻导读
        .sidebar{
          display: none;
        }
      }
    }
  }
}
</style>
